import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

import styles from "./home.module.scss"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>
        A little about <span>me...</span>
      </h1>
      <p>
        Hello, my name is James and I have a passion for web development and
        problem solving. I am a self taught programmer that began in Python but
        found my way to Javascript and absolutely became fascinated by front end
        development. I enjoy and work in multiple facets of coding from
        documentation to podcasts. My current skill set includes:
      </p>
      <ul>
        <li>HTML</li>
        <li>CSS/SASS (Grid and Flexbox)</li>
        <li>React</li>
        <li>Redux</li>
        <li>Typescript</li>
        <li>NodeJS</li>
        <li>MongoDB</li>
        <li>GatsbyJS</li>
      </ul>

      <p>
        While I work to master my current skills, I also find time to introduce
        challenges by adding in more skills along the way. I a slight obsession
        with finely brewed coffee. Cheers!
      </p>

      <p>
        If you'd like to know more or see some examples of my work please{" "}
        <Link to="/contact">Contact me.</Link>
      </p>
    </Layout>
  )
}

export default AboutPage
